import React from "react"

import BaseStructure from "../components/site/baseStructure"
import Seo from "../components/site/seo"
import PageHeader from "../components/elements/pageHeader"

import Container from "@material-ui/core/Container"

const AboutPage = ({ location }) => {
  return (
    <BaseStructure location={location}>
      <Seo title="Privacy Policy" />
      <Container className="standardWidth">
        <PageHeader title="Privacy Policy" />
        <p>This site does not store any of your personal data.</p>
        <p>
          This site may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party’s site. We advise you to review the Privacy Policy of
          every site you visit.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          by email namawalks@gmail.com
        </p>
        <p>Last updated on 20 February 2021.</p>
      </Container>
    </BaseStructure>
  )
}

export default AboutPage
